<template>
  <div class="content">
    <div
      id="my-slider"
      :se-min="minThreshold"
      :se-step="step"
      :se-min-value="min"
      :se-max-value="max"
      :se-max="maxThreshold"
      class="slider"
    >
      <div class="slider-touch-left">
        <span />
      </div>
      <div class="slider-touch-right">
        <span />
      </div>
      <div class="slider-line">
        <span />
      </div>
    </div>
  </div>
</template>

<script>
import ZbRangeSlider from "@/utilities/zbRangeSlider";

export default {
  props: {
    minThreshold: {
      type: Number,
      default: 0
    },
    maxThreshold: {
      type: Number,
      default: 10000000
    },
    step: {
      type: Number,
      default: 1
    },
    min: {
      type: [Number, String],
      required: true
    },
    max: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      instance: undefined
    };
  },
  mounted() {
    this.instance = new ZbRangeSlider("my-slider");
    this.instance.onChange = (min, max) => this.updateValues(min, max);
  },
  destroyed() {},
  methods: {
    updateValues(min, max) {
      this.$emit("update:min", min);
      this.$emit("update:max", max);
    }
  }
};
</script>

<style>
.slider {
  display: block;
  position: relative;
  height: 36px;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.slider .slider-touch-left,
.slider .slider-touch-right {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  position: absolute;
  height: 14px;
  width: 14px;
  top: calc(50% - 14px / 2);
  /* padding: 2px; */
  z-index: 2;
}
.slider .slider-touch-left span,
.slider .slider-touch-right span {
  display: block;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 2px solid #f15a29;
  border-radius: 50%;
}
.slider .slider-line {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  top: 16px;
  height: 4px;
  border-radius: 4px;
  background: #eeeeee;
  z-index: 0;
  overflow: hidden;
}
.slider .slider-line span {
  display: block;
  height: 100%;
  width: 0%;
  background: #f15a29;
}
</style>
